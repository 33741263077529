export default {
  name: 'email',
  component: 'input',
  label: 'E-postadress',
  value: '',
  prefixIcon: 'el-icon-s-promotion',
  validation: {
    rules: ['email', 'required']
  }
};
