export default {
  name: 'loginPassword',
  component: 'input',
  type: 'password',
  label: 'Lösenord',
  value: '',
  prefixIcon: 'el-icon-lock',
  validation: {
    rules: ['password']
  }
};
