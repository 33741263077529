export default {
  user (state, user) {
    if (!user) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', JSON.stringify({
        id: user ? user.id : null,
        role: user ? user.role : null,
        createdAt: user ? user.createdAt : null
      }));
    }

    state.address = user ? user.address : null;
    state.id = user ? user.id : null;
    state.createdAt = user ? user.createdAt : null;
    state.name = user ? user.name : null;
    state.email = user ? user.email : null;
    state.phone = user ? user.phone : null;
    state.role = user ? user.role : null;
    state.isCompany = user ? user.isCompany : null;
    state.companyName = user ? user.companyName : null;
    state.orgNumber = user ? user.orgNumber : null;
  },
  loggedIn (state, loggedIn) {
    state.loggedIn = loggedIn;
  },
  address (state, address) {
    state.address = address;
  },
  id (state, id) {
    state.id = id;
  },
  createdAt (state, createdAt) {
    state.createdAt = createdAt;
  },
  name (state, name) {
    state.name = name;
  },
  email (state, email) {
    state.email = email;
  },
  loginEmail (state, email) {
    state.loginEmail.value = email;
  },
  loginPassword (state, password) {
    state.loginPassword.value = password;
  },
  repeatedPassword (state, repeatedPassword) {
    state.repeatedPassword = repeatedPassword;
  },
  recoveryToken (state, recoveryToken) {
    state.recoveryToken = recoveryToken;
  },
  phone (state, phone) {
    state.phone = phone;
  },
  role (state, role) {
    state.role = role;
  },
  isCompany (state, isCompany) {
    state.isCompany = isCompany;
  },
  companyName (state, companyName) {
    state.companyName = companyName;
  },
  orgNumber (state, orgNumber) {
    state.orgNumber = orgNumber;
  },
  loading (state, loading) {
    state.loading = loading;
  },
  error (state, error) {
    state.error = error;
  }
};
