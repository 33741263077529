import { createStore } from 'vuex';

import contactUs from './modules/contactUs';
import offers from './modules/offers';
import user from './modules/user';
import events from './modules/events';

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    contactUs,
    offers,
    user,
    events
  }
});
