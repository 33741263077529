import fields from '@/forms/offersForm';

export default {
  total: fields.total,
  categories: fields.categories,
  municipality: fields.municipality,
  order: null,
  ascending: true,
  preferredPartner: null,

  offers: null,
  selectedOffer: null,

  name: fields.name,
  email: fields.email,
  phone: fields.phone,

  isCompany: fields.isCompany,
  companyName: fields.companyName,
  orgNumber: fields.orgNumber,

  sameInvoiceAddress: fields.sameInvoiceAddress,
  streetName: fields.streetName,
  postalCode: fields.postalCode,
  city: fields.city,

  invoiceStreetName: fields.invoiceStreetName,
  invoiceMarking: fields.invoiceMarking,
  invoicePostalCode: fields.invoicePostalCode,
  invoiceCity: fields.invoiceCity,

  date: fields.date,
  time: fields.time,
  message: fields.message,
  compare: fields.compare,
  submit: fields.submit,

  loading: false,
  invalidSearch: false,
  error: false,
  submited: false
};
