import router from '@/router';

import { offersFormMapper } from '../../mapper';
import partners from '@/content/Partners';

const { serviceCaterify } = require('@/lib/axios').default;
const { useGtag } = require('vue-gtag-next');

function GATrack (action, category, label, value) {
  const { event } = useGtag();

  event(action, {
    event_category: category,
    event_label: label,
    event_value: value
  });
}

function conversionTrack (sendTo) {
  const { event } = useGtag();

  event('conversion', {
    send_to: sendTo
  });
}

export default {
  submitSearchForm ({ state, commit }) {
    if (state.total.value && parseInt(state.total.value)) {
      const params = {
        total: parseInt(state.total.value),
        categories: state.categories.value || null,
        municipality: state.municipality.value || null,
        order: state.order || null,
        ascending: state.ascending || null
      };

      commit('loading', true);
      commit('error', false);
      commit('invalidSearch', false);

      router.push({ path: '/offerter' });

      serviceCaterify.get('/offers', {
        params
      }).then(({ data }) => {
        if (data.success) {
          commit('offers', processOffers(data.offers));

          GATrack('search events successfully', 'event', 'search');
          conversionTrack('AW-988848528/wBXvCP_xhKMDEJDDwtcD');
        } else {
          if (data.code === 'invalidSearch') {
            commit('invalidSearch', true);
          } else {
            commit('error', 'serviceError');
          }

          GATrack('search events not successfully', 'event', 'search');
        }
        commit('loading', false);
      }).catch((e) => {
        commit('error', 'serviceError');
        commit('loading', false);

        GATrack('search events not successfully', 'event', 'search');
      });
    } else {
      GATrack('search events not successfully', 'event', 'search');

      commit('invalidSearch', true);
      commit('loading', false);
    }
  },
  selectOffer ({ commit }, data) {
    commit('selectedOffer', data);

    GATrack('selected offer successfully', 'event', 'selectOffer', data.id);
  },
  loadUserData ({ commit, rootGetters }) {
    commit('isCompany', !!rootGetters['user/isCompany']);
    commit('companyName', rootGetters['user/companyName']);
    commit('orgNumber', rootGetters['user/orgNumber']);

    commit('name', rootGetters['user/name']);
    commit('email', rootGetters['user/email']);
    commit('phone', rootGetters['user/phone']);

    const address = rootGetters['user/address'];
    if (address) {
      const deliveryAddress = address && address.filter(address => address.type === 'DELIVERY' || address.type === 'BOTH')[0];
      const invoiceAddress = address && address.filter(address => address.type === 'INVOICE' || address.type === 'BOTH')[0];
      const sameAddress = deliveryAddress.streetName === invoiceAddress.streetName;

      GATrack('Preloaded user data successfully', 'event', 'loadUserData');

      commit('sameInvoiceAddress', sameAddress);
      commit('streetName', deliveryAddress.streetName);
      commit('postalCode', deliveryAddress.postalCode);
      commit('city', deliveryAddress.city);

      if (!sameAddress) {
        commit('invoiceStreetName', invoiceAddress.streetName);
        commit('invoiceMarking', invoiceAddress.marking);
        commit('invoicePostalCode', invoiceAddress.postalCode);
        commit('invoiceCity', invoiceAddress.city);
      }
    }
  },
  submitOfferForm ({ state, commit }) {
    commit('loading', true);
    commit('error', false);

    const {
      contactInfo,
      companyInfo,
      eventInfo,
      invoiceAddress,
      selectedOffer
    } = offersFormMapper(state);

    serviceCaterify.post('/offers', {
      contactInfo,
      companyInfo,
      eventInfo,
      invoiceAddress,
      selectedOffer
    }).then(({ data }) => {
      if (data.success) {
        commit('submited', true);

        GATrack('submit offer successfully', 'event', 'submitOffer');
        conversionTrack('AW-988848528/taUvCILyhKMDEJDDwtcD');
      } else {
        commit('error', 'serviceError');

        GATrack('submit offer not successfully', 'event', 'submitOffer');
      }
      commit('loading', false);
    }).catch((e) => {
      commit('error', 'serviceError');
      commit('loading', false);

      GATrack('submit offer successfully', 'event', 'submitOffer');
    });
  }
};

function processOffers (offers) {
  return offers.map(offer => {
    return { ...offer, ...partners.find(partner => partner.id === offer.id) };
  });
}
