export default {
  name: 'date',
  component: 'date',
  label: 'Eventets datum',
  type: 'date',
  value: '',
  validation: {
    rules: ['futureDate']
  }
};
