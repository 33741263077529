export default {
  name: 'city',
  component: 'input',
  label: 'Postort',
  value: '',
  prefixIcon: 'el-icon-location-information',
  validation: {
    rules: ['required']
  }
};
