export default {
  name: 'postalCode',
  component: 'input',
  label: 'Postnummer',
  value: '',
  prefixIcon: 'el-icon-location-information',
  maxLength: '5',
  showWordLimit: true,
  validation: {
    parser: 'noSpace',
    rules: ['postalCode']
  }
};
