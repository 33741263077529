import name from './name';
import email from './email';
import message from './message';

import submit from './submit';

export default {
  name,
  email,
  message,

  submit
};
