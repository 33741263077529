import { mapGetters, useStore } from 'vuex';
import { useForm } from 'vee-validate';
import FormInput from '@/components/FormInput';
import Loading from '@/components/Loading';
export default {
  name: 'Offers',
  components: {
    FormInput,
    Loading
  },
  props: {
    partner: {
      type: String,
      required: false,
      default: ''
    },
    usps: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },

  setup(props) {
    const store = useStore();
    const {
      handleSubmit,
      isSubmitting
    } = useForm();
    const onSubmit = handleSubmit(() => {
      store.commit('offers/preferredPartner', props.partner || false);
      store.dispatch('offers/submitSearchForm');
    });
    return {
      onSubmit,
      isSubmitting
    };
  },

  computed: { ...mapGetters('offers', ['total', 'municipality', 'categories', 'loading', 'compare'])
  }
};