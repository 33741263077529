export default {
  name: 'name',
  component: 'input',
  label: 'För- och efternamn',
  value: '',
  prefixIcon: 'el-icon-user',
  validation: {
    rules: ['required']
  }
};
