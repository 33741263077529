export default {
  name: 'invoiceMarking',
  component: 'input',
  label: 'Faktura märkering',
  value: '',
  prefixIcon: 'el-icon-s-order',
  autoComplete: 'billing address-line2',
  maxLength: 64,
  validation: {
    rules: ['']
  }
};
