import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-9e6425a4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "form-input"
};
const _hoisted_2 = ["for"];
const _hoisted_3 = {
  key: 10,
  class: "error-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton");

  const _component_ElInput = _resolveComponent("ElInput");

  const _component_ElInputNumber = _resolveComponent("ElInputNumber");

  const _component_ElOption = _resolveComponent("ElOption");

  const _component_ElSelect = _resolveComponent("ElSelect");

  const _component_ElDatePicker = _resolveComponent("ElDatePicker");

  const _component_ElTimeSelect = _resolveComponent("ElTimeSelect");

  const _component_ElSlider = _resolveComponent("ElSlider");

  const _component_ElSwitch = _resolveComponent("ElSwitch");

  const _component_ElCheckbox = _resolveComponent("ElCheckbox");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.content.showLabel || ($data.value && !Array.isArray($data.value) || Array.isArray($data.value) && $data.value.length > 0 || typeof $data.value === 'boolean') && $props.content.component !== 'checkbox' ? (_openBlock(), _createElementBlock("label", {
    key: 0,
    class: _normalizeClass(["label", [{
      'switch-label': $props.content.component === 'switch'
    }]]),
    for: $props.content.name
  }, _toDisplayString($props.content.label), 11, _hoisted_2)) : _createCommentVNode("", true), $props.display && $props.content.component === 'button' ? (_openBlock(), _createBlock(_component_ElButton, {
    key: 1,
    type: $props.content.type,
    "prefix-icon": $props.content.prefixIcon,
    "suffix-icon": $props.content.suffixIcon,
    plain: $props.content.plain,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('onClick'))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.label ? $props.label : $props.content.label), 1)]),
    _: 1
  }, 8, ["type", "prefix-icon", "suffix-icon", "plain"])) : $props.display && $props.content.component === 'input' ? (_openBlock(), _createBlock(_component_ElInput, {
    key: 2,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.value = $event),
    type: $props.content.type,
    name: $props.content.name,
    label: $props.content.label,
    placeholder: $props.content.placeholder || $props.content.label,
    "prefix-icon": $props.content.prefixIcon,
    "suffix-icon": $props.content.suffixIcon,
    maxlength: $props.content.maxLength,
    "show-word-limit": $props.content.showWordLimit,
    autocomplete: "on",
    autosize: $props.content.autosize,
    onInput: $options.updateValue
  }, null, 8, ["modelValue", "type", "name", "label", "placeholder", "prefix-icon", "suffix-icon", "maxlength", "show-word-limit", "autosize", "onInput"])) : $props.content.component === 'number' ? (_openBlock(), _createBlock(_component_ElInputNumber, {
    key: 3,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.value = $event),
    name: $props.content.name,
    label: $props.content.label,
    placeholder: $props.content.placeholder || $props.content.label,
    min: $props.content.min,
    max: $props.content.max,
    controls: $props.content.controls,
    "aria-label": $props.content.label,
    onChange: $options.updateValue
  }, null, 8, ["modelValue", "name", "label", "placeholder", "min", "max", "controls", "aria-label", "onChange"])) : $props.content.component === 'select' ? (_openBlock(), _createBlock(_component_ElSelect, {
    key: 4,
    id: $props.content.name,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.value = $event),
    label: $props.content.label,
    placeholder: $props.content.placeholder || $props.content.label,
    name: $props.content.name,
    disabled: $props.disabled,
    filterable: $options.isMobile ? false : $props.content.filterable,
    multiple: $props.content.multiple,
    "no-match-text": "Inga resultat hittades",
    "no-data-text": "Inga resultat hittades",
    "default-first-option": "",
    "allow-create": $props.content.allowCreate,
    onChange: $options.updateValue
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.content.options, option => {
      return _openBlock(), _createBlock(_component_ElOption, {
        key: option,
        value: option
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(option), 1)]),
        _: 2
      }, 1032, ["value"]);
    }), 128))]),
    _: 1
  }, 8, ["id", "modelValue", "label", "placeholder", "name", "disabled", "filterable", "multiple", "allow-create", "onChange"])) : $props.content.component === 'date' ? (_openBlock(), _createBlock(_component_ElDatePicker, {
    key: 5,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.value = $event),
    type: $props.content.type,
    placeholder: $props.content.placeholder || $props.content.label,
    name: $props.content.name,
    onChange: $options.updateValue
  }, null, 8, ["modelValue", "type", "placeholder", "name", "onChange"])) : $props.content.component === 'time' ? (_openBlock(), _createBlock(_component_ElTimeSelect, {
    key: 6,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.value = $event),
    start: $props.content.start,
    step: $props.content.step,
    end: $props.content.end,
    placeholder: $props.content.placeholder || $props.content.label,
    onChange: $options.updateValue
  }, null, 8, ["modelValue", "start", "step", "end", "placeholder", "onChange"])) : $props.content.component === 'slider' ? (_openBlock(), _createBlock(_component_ElSlider, {
    key: 7,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.value = $event),
    "show-input": $props.content.showInput,
    "show-input-controls": $props.content.showInputControls,
    label: $props.content.label,
    min: $props.content.min,
    max: $props.content.max,
    disabled: $props.disabled,
    "format-tooltip": $props.disabled ? () => {
      return $props.content.disabledFormatTooltip;
    } : null,
    onChange: $options.updateValue
  }, null, 8, ["modelValue", "show-input", "show-input-controls", "label", "min", "max", "disabled", "format-tooltip", "onChange"])) : $props.content.component === 'switch' ? (_openBlock(), _createBlock(_component_ElSwitch, {
    key: 8,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.value = $event),
    name: $props.content.name,
    label: $props.content.label,
    "active-color": "#13ce66",
    onChange: $options.updateValue
  }, null, 8, ["modelValue", "name", "label", "onChange"])) : $props.content.component === 'checkbox' ? (_openBlock(), _createBlock(_component_ElCheckbox, {
    key: 9,
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.value = $event),
    name: $props.content.name,
    label: $props.content.label,
    size: $props.content.size,
    onChange: $options.updateValue
  }, null, 8, ["modelValue", "name", "label", "size", "onChange"])) : _createCommentVNode("", true), $data.errorMessage ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString($data.errorMessage), 1)) : _createCommentVNode("", true)]);
}