import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import partners from '@/content/Partners';
import articles from '@/content/Articles';

import store from '@/store/index';

function PartnerRoutes () {
  return partners.map(partner => {
    return {
      path: `/${partner.id}`,
      name: partner.name,
      component: () => import(/* webpackChunkName: "PartnerPage" */ '../views/PartnerPage.vue'),
      meta: {
        title: `Boka ${partner.name} - Caterify`,
        description: partner.description.replace(/<.+?>/gm, ''),
        keywords: partner.keywords
      }
    };
  });
}

function ArticleRoutes () {
  return articles.map(article => {
    return {
      path: `/${article.slug}`,
      name: article.title,
      component: () => import(/* webpackChunkName: "ArticlePage" */ '../views/ArticlePage.vue'),
      meta: {
        title: article.title + ' - Caterify',
        description: article.subTitle,
        keywords: article.keywords
      }
    };
  });
}

const routes = [
  {
    path: '/',
    name: 'Hem',
    component: Home
  },
  {
    path: '/offerter',
    name: 'Offerter',
    component: () => import(/* webpackChunkName: "Offers" */ '../views/Offers.vue'),
    meta: {
      title: 'Välj offert - Caterify',
      robots: 'noindex'
    }
  },
  {
    path: '/sammanfattning',
    name: 'Sammanfattning',
    component: () => import(/* webpackChunkName: "summary", */ '../views/SelectOffer.vue'),
    meta: {
      title: 'Sammanfattning - Caterify',
      robots: 'noindex'
    }
  },
  {
    path: '/mina-sidor',
    name: 'Mina Sidor',
    component: () => import(/* webpackChunkName: "myPages", */ '../views/MyPages.vue'),
    meta: {
      title: 'Mina Sidor - Caterify',
      auth: true
    }
  },
  {
    path: '/mina-sidor/login',
    name: 'Logga in - Caterify',
    component: () => import(/* webpackChunkName: "login", */ '../views/Login.vue'),
    meta: {
      title: 'Logga in - Mina Sidor - Caterify'
    }
  },
  {
    path: '/mina-sidor/recovery',
    name: 'Återställa lösenord - Caterify',
    component: () => import(/* webpackChunkName: "login", */ '../views/PasswordRecovery.vue'),
    meta: {
      title: 'Återställa lösenord - Caterify'
    }
  },
  {
    path: '/mina-sidor/verify',
    name: 'Bekräfta din epost-adress - Caterify',
    component: () => import(/* webpackChunkName: "login", */ '../views/VerifyUser.vue'),
    meta: {
      title: 'Bekräfta din epost-adress - Caterify',
      robots: 'noindex'
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import(/* webpackChunkName: "Partners" */ '../views/Partners.vue'),
    meta: {
      title: 'Våra partners - Caterify',
      description: 'Caterify\'s alla partners och catering leverantörer, se deras meny, priser och boka direkt online. Alla våra partners är handplockade och garanterar väldigt hög kvalite.'
    }
  },
  {
    path: '/bli-partner',
    name: 'Bli partner',
    component: () => import(/* webpackChunkName: "BecomePartner" */ '../views/BecomePartner.vue'),
    meta: {
      title: 'Bli vår partner - Caterify',
      description: 'Bli en catering leverantörer och partner till Caterify. Vi förser er med kunder och hjälper er att fokusera på det ni gör bäst, vi tar inte några extra avgifter och det är helt kostnadsfritt att vara med i vår panel.'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "BecomePartner" */ '../views/Faq.vue'),
    meta: {
      title: 'FAQ - caterify',
      description: 'Caterify\'s FAQ(frequently asked questions) eller frågor och svar, vi svarar på alla dina frågor och lite till. Undrar du över något mer? Hör av dig till oss.'
    }
  },
  {
    path: '/anvandarvillkor',
    name: 'Användarvillkor',
    component: () => import(/* webpackChunkName: "BecomePartner" */ '../views/PrivacyPolicy.vue'),
    meta: {
      title: 'Användarvilkor och Dataskydd - Caterify',
      description: 'Dessa Användarvillkor styr interaktionen med Caterify och användningen av våra tjänster. Det förklarar även hur vi hanterar dina personuppgifter och hur vi följer dataskyddsförordningen GDPR.'
    }
  },
  ...PartnerRoutes(),
  ...ArticleRoutes()
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['user/loggedIn'];
  const email = store.getters['user/email'];
  const error = store.getters['user/error'];

  if (loggedIn && !email && !error) {
    store.dispatch('user/getUser');
  }

  if (to.meta.auth && !loggedIn) {
    next({ path: '/mina-sidor/login' });
  } else if (to.path === '/mina-sidor/login' && loggedIn) {
    next({ path: '/mina-sidor' });
  } else {
    document.title = to.meta.title || 'Caterify - Jämför och hyr foodtruck till alla dina evenemang';

    if (to.meta.robots) {
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = to.meta.robots;
      document.head.appendChild(metaRobots);
    } else if (document.querySelector('meta[name=\'robots\']')) {
      document.querySelector('meta[name=\'robots\']').remove();
    }

    const description = document.getElementsByName('description')[0];
    description.content = to.meta.description ||
      'Caterify hjälper dig att hyra foodtruck i Stockholm till alla dina evenemang. Genom att jämföra flera foodtrucks i Stockholm samtidigt får du det bästa priset direkt.';

    const keywords = document.getElementsByName('keywords')[0];
    keywords.content = (to.meta.keywords ? `${to.meta.keywords}, ` : '') + 'catering i stockholm, foodtruck i stockholm, catering, Stockholm, foodtruck, mat, hemleverans, lunch, middag, AW, afterwork, bygglunch';

    window.scroll({
      top: 0,
      left: 0
    });

    next();
  }
});

export default router;
