export default {
  name: 'invoiceStreetName',
  component: 'input',
  label: 'Fakturaadress',
  value: '',
  prefixIcon: 'el-icon-s-order',
  autoComplete: 'billing street-address',
  maxLength: 32,
  validation: {
    rules: ['required']
  }
};
