import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-header"
};
const _hoisted_2 = {
  class: "app-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Navbar = _resolveComponent("Navbar");

  const _component_router_view = _resolveComponent("router-view");

  const _component_CookieConsent = _resolveComponent("CookieConsent");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("header", _hoisted_1, [_createVNode(_component_Navbar)]), _createElementVNode("main", _hoisted_2, [_createVNode(_component_router_view)]), _createVNode(_component_CookieConsent), _createVNode(_component_Footer, {
    class: "app-footer"
  })], 64);
}