import total from './total';
import categories from './categories';
import municipality from './municipality';

import name from './name';
import email from './email';
import phone from './phone';

import isCompany from './isCompany';
import companyName from './companyName';
import orgNumber from './orgNumber';

import sameInvoiceAddress from './sameInvoiceAddress';
import streetName from './streetName';
import postalCode from './postalCode';
import city from './city';

import invoiceStreetName from './invoiceStreetName';
import invoiceMarking from './invoiceMarking';
import invoicePostalCode from './invoicePostalCode';
import invoiceCity from './invoiceCity';

import date from './date';
import time from './time';
import message from './message';

import submit from './submit';
import compare from './compare';

export default {
  total,
  categories,
  municipality,

  name,
  email,
  phone,

  isCompany,
  companyName,
  orgNumber,

  sameInvoiceAddress,
  streetName,
  postalCode,
  city,

  invoiceStreetName,
  invoiceMarking,
  invoicePostalCode,
  invoiceCity,

  date,
  time,
  message,
  submit,
  compare
};
