const { serviceCaterify } = require('@/lib/axios').default;

const { useGtag } = require('vue-gtag-next');

function GATrack (action, category, label) {
  const { event } = useGtag();

  event(action, {
    event_category: category,
    event_label: label
  });
}

export default {
  loadMyEvents ({ commit }) {
    commit('loading', true);
    serviceCaterify.get('/events/all')
      .then(({ data }) => {
        commit('events', data.events);
        commit('error', false);
      }).catch(_ => {
        commit('error', true);
      }).finally(() => {
        commit('loading', false);
      });
  },
  clearMyEvents ({ commit }) {
    commit('events', null);
    commit('archivedOffers', false);
    commit('eventsSearch', null);
    commit('error', false);
  },
  loadEvent ({ commit }, id) {
    commit('loading', true);
    serviceCaterify.get(`/events/${id}`)
      .then(({ data }) => {
        commit('event', data.event);
        commit('error', false);
      }).catch(() => {
        commit('error', true);
      }).finally(() => {
        commit('loading', false);
      });
  },
  confirmEvent ({ commit }, id) {
    return new Promise((resolve, reject) => {
      serviceCaterify.post('/events/confirm', { id: id })
        .then(({ data }) => {
          GATrack('confirm event successfully', 'event', 'confirm');

          commit('event', data.event);
          commit('error', false);
          resolve();
        }).catch((err) => {
          GATrack('confirm event not successfully', 'event', 'confirm');

          commit('error', true);
          reject(err);
        }).finally(() => {
          commit('loading', false);
        });
    });
  },
  declineEvent ({ commit }, id) {
    return new Promise((resolve, reject) => {
      serviceCaterify.post('/events/decline', { id: id })
        .then(({ data }) => {
          GATrack('decline event successfully', 'event', 'decline');

          commit('event', data.event);
          commit('error', false);
          resolve();
        }).catch((err) => {
          GATrack('decline event not successfully', 'event', 'decline');

          commit('error', true);
          reject(err);
        }).finally(() => {
          commit('loading', false);
        });
    });
  },
  archiveEvent ({ commit }, id) {
    return new Promise((resolve, reject) => {
      serviceCaterify.post('/events/archive', { id: id })
        .then(({ data }) => {
          GATrack('archived event successfully', 'event', 'archive');

          commit('event', data.event);
          commit('error', false);
          resolve();
        }).catch((err) => {
          GATrack('archive event not successfully', 'event', 'archive');

          commit('error', true);
          reject(err);
        }).finally(() => {
          commit('loading', false);
        });
    });
  },
  sendMessage ({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('loading', true);
      serviceCaterify.post('/events/message',
        {
          id: params.id,
          message: params.message
        })
        .then(({ data }) => {
          commit('loading', false);
          return resolve(data);
        }).catch((err) => {
          commit('error', true);
          commit('loading', false);
          return reject(err);
        });
    });
  },
  readMessage ({ commit }, params) {
    serviceCaterify.post('/events/message/read', {
      id: params.id,
      time: params.time
    });

    setTimeout(() => {
      commit('readMessage', params);
    }, 3000);
  },
  async createCustomerInvoice ({ dispatch }, id) {
    await serviceCaterify.post(`/events/invoice/${id}`);

    setTimeout(() => {
      dispatch('loadEvent', id);
    }, 1000);
  },
  async publishCustomerInvoice ({ dispatch }, id) {
    await serviceCaterify.post(`/events/invoice/${id}/publish`);

    setTimeout(() => {
      dispatch('loadEvent', id);
    }, 1000);
  },
  async customerInvoicePaid ({ dispatch }, id) {
    await serviceCaterify.post(`/events/invoice/${id}/paid`);

    setTimeout(() => {
      dispatch('loadEvent', id);
    }, 1000);
  },

  async createPartnerInvoice ({ dispatch }, id) {
    await serviceCaterify.post(`/events/partner/invoice/${id}`);

    setTimeout(() => {
      dispatch('loadEvent', id);
    }, 1000);
  },
  async publishPartnerInvoice ({ dispatch }, id) {
    await serviceCaterify.post(`/events/partner/invoice/${id}/publish`);

    setTimeout(() => {
      dispatch('loadEvent', id);
    }, 1000);
  },
  async partnerInvoicePaid ({ dispatch }, id) {
    await serviceCaterify.post(`/events/partner/invoice/${id}/paid`);

    setTimeout(() => {
      dispatch('loadEvent', id);
    }, 1000);
  }
};
