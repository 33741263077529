export default {
  total (state, total) {
    state.total.value = total;
  },
  categories (state, categories) {
    state.categories.value = categories;
  },
  municipality (state, municipality) {
    state.municipality.value = municipality;
  },
  order (state, order) {
    state.order = order;
  },
  ascending (state, ascending) {
    state.ascending = ascending;
  },
  preferredPartner (state, preferredPartner) {
    state.preferredPartner = preferredPartner;
  },

  offers (state, offers) {
    state.offers = offers;
  },
  selectedOffer (state, selectedOffer) {
    state.selectedOffer = selectedOffer;
  },

  name (state, name) {
    state.name.value = name;
  },
  email (state, email) {
    state.email.value = email;
  },
  phone (state, phone) {
    state.phone.value = phone;
  },

  isCompany (state, isCompany) {
    state.isCompany.value = isCompany;
  },
  companyName (state, companyName) {
    state.companyName.value = companyName;
  },
  orgNumber (state, orgNumber) {
    state.orgNumber.value = orgNumber;
  },

  sameInvoiceAddress (state, sameInvoiceAddress) {
    state.sameInvoiceAddress.value = sameInvoiceAddress;
  },
  streetName (state, streetName) {
    state.streetName.value = streetName;
  },
  postalCode (state, postalCode) {
    state.postalCode.value = postalCode;
  },
  city (state, city) {
    state.city.value = city;
  },

  invoiceStreetName (state, invoiceStreetName) {
    state.invoiceStreetName.value = invoiceStreetName;
  },
  invoiceMarking (state, invoiceMarking) {
    state.invoiceMarking.value = invoiceMarking;
  },
  invoicePostalCode (state, invoicePostalCode) {
    state.invoicePostalCode.value = invoicePostalCode;
  },
  invoiceCity (state, invoiceCity) {
    state.invoiceCity.value = invoiceCity;
  },

  date (state, date) {
    if (date) {
      date = new Date(date);
      state.date.value = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice('-2')}-${date.getDate()}`;
    } else {
      state.date.value = null;
    }
  },
  time (state, time) {
    state.time.value = time;
  },
  message (state, message) {
    state.message.value = message;
  },

  loading (state, loading) {
    state.loading = loading;
  },
  invalidSearch (state, invalidSearch) {
    state.invalidSearch = invalidSearch;
  },
  error (state, error) {
    state.error = error;
  },
  submited (state, submited) {
    state.submited = submited;
  }
};
