import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/icons/arrow-up.svg';
import _imports_1 from '../assets/icons/arrow-down.svg';

const _withScopeId = n => (_pushScopeId("data-v-5d3edb2b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "accordion"
};
const _hoisted_2 = {
  class: "accordion--header"
};
const _hoisted_3 = {
  class: "accordion--header--title"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 0,
  class: "accordion--header--subtitle"
};
const _hoisted_6 = {
  class: "accordion--wrapper"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  key: 0,
  class: "icon",
  src: _imports_0,
  alt: "Förminska fråga och göm svar"
};
const _hoisted_9 = {
  key: 1,
  src: _imports_1,
  class: "icon el-icon-plus",
  alt: "Utöka fråga och se svar"
};
const _hoisted_10 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString($props.title), 1)) : _createCommentVNode("", true)]), $props.subTitle ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.subTitle), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.accordions, (faq, questionIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: faq.question,
      class: _normalizeClass([[{
        'accordion--faq--active': $data.expandedQuestion === questionIndex
      }], "accordion--faq"])
    }, [_createElementVNode("h4", {
      class: "accordion--faq--question",
      onClick: $event => $options.toggleExpanded(questionIndex)
    }, [_createElementVNode("span", null, _toDisplayString(faq.question), 1), $data.expandedQuestion === questionIndex ? (_openBlock(), _createElementBlock("img", _hoisted_8)) : (_openBlock(), _createElementBlock("img", _hoisted_9))], 8, _hoisted_7), _withDirectives(_createElementVNode("p", {
      class: _normalizeClass([[{
        'accordion--faq--answer--active': $data.expandedQuestion === questionIndex
      }], "accordion--faq--answer"])
    }, [_createElementVNode("span", {
      innerHTML: faq.answer
    }, null, 8, _hoisted_10)], 2), [[_vShow, $data.expandedQuestion === questionIndex]])], 2);
  }), 128))])]);
}