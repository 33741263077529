export default {
  required: function (value) {
    if (!value || (!parseInt(value) && !value.length)) {
      return 'Fältet är obligatoriskt';
    }

    return true;
  },
  email: function (value) {
    if (!value || !value.length) {
      return 'Fältet är obligatoriskt';
    }

    // Check if email
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      return 'Ange en riktig e-postadress';
    }

    return true;
  },
  password: function (value) {
    if (!value || !value.length) {
      return 'Fältet är obligatoriskt';
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)) {
      return 'Ange ett giltigt lösenord';
    }

    return true;
  },
  phone: function (value) {
    if (!value || !value.length) {
      return 'Fältet är obligatoriskt';
    }

    if (!/^(07[0236]|08\d{1})(\d{5,9})$/.test(value)) {
      return 'Ange ett riktigt telefonnummer';
    }

    return true;
  },
  futureDate: function (value) {
    if (!value) {
      return 'Fältet är obligatoriskt';
    }

    if (value < new Date()) {
      return 'Eventet får inte ha paserat';
    }

    return true;
  },
  orgNumber: function (value) {
    if (!value) {
      return 'Fältet är obligatoriskt';
    }

    if (!/^\d{6}[-]?\d{4}$/.test(value)) {
      return 'Ange ett riktigt organisationsnummer';
    }

    return true;
  },
  postalCode: function (value) {
    if (!value) {
      return 'Fältet är obligatoriskt';
    }

    if (!/^\d{5}/.test(value)) {
      return 'Ange ett riktigt postnummer';
    }

    return true;
  }
};
