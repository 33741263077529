export default {
  name: 'UspCards',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    uspCards: {
      type: Array[Object],
      required: true
    }
  },
  methods: {
    imageUrl(imageUrl) {
      return require('../assets/icons/' + imageUrl);
    }

  }
};