import Navbar from '@/components/Navbar';
import CookieConsent from '@/components/CookieConsent';
import Footer from '@/components/Footer';
export default {
  name: 'Caterify',
  components: {
    Navbar,
    CookieConsent,
    Footer
  },

  created() {
    const currentPath = window.location.pathname;
    const pathExist = this.$router.getRoutes().some(route => {
      return route.path === currentPath && route.path;
    });
    pathExist ? this.$router.replace(currentPath + window.location.search) : this.$router.replace('/');
  }

};