import fields from '@/forms/events';

export default {
  events: null,

  archivedOffers: fields.archivedOffers,
  eventsSearch: fields.eventsSearch,

  loading: false,
  error: false
};
