import { ElCard } from 'element-plus';
import Articles from '@/content/Articles/index';
export default {
  name: 'ArticleCards',
  components: {
    ElCard
  },
  props: {
    show: {
      type: Number,
      required: false,
      default: null
    }
  },

  setup(props) {
    var processedArticles = Articles;

    if (props.show && parseInt(props.show)) {
      processedArticles = processedArticles.filter((_, index) => index < props.show);
    }

    processedArticles.articles = processedArticles.map(article => {
      return {
        title: article.title,
        subTitle: article.subTitle.slice(0, 169) + (article.subTitle.length > 169 ? '...' : ''),
        image: article.image,
        slug: article.slug
      };
    });
    return {
      articles: processedArticles
    };
  },

  methods: {
    imageUrl(imageUrl) {
      return require('@/assets/articles/' + imageUrl);
    }

  }
};