export default {
  name: 'total',
  component: 'slider',
  label: 'Antal portioner',
  value: 50,
  min: 10,
  max: 300,
  showInput: true,
  showInputControls: false,
  disabledFormatTooltip: 'Gör en ny sökning på startsidan för att ändra antal portioner',
  validation: {
    rules: ['required']
  }
};
