export default {
  name: 'orgNumber',
  component: 'input',
  label: 'Organisationsnummer',
  value: '',
  prefixIcon: 'el-icon-s-cooperation',
  validation: {
    rules: ['orgNumber']
  }
};
