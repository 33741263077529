import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-21dc629b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "usp-cards-wrapper"
};
const _hoisted_2 = {
  class: "usp-cards-header"
};
const _hoisted_3 = {
  class: "usp-cards"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "usp-cards-item--text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h3", null, _toDisplayString($props.title), 1), _createElementVNode("p", null, _toDisplayString($props.subTitle), 1)]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.uspCards, uspCard => {
    return _openBlock(), _createElementBlock("div", {
      key: uspCard.title,
      class: "usp-cards-item"
    }, [_createElementVNode("img", {
      class: "usp-cards-item--icon",
      src: $options.imageUrl(uspCard.icon)
    }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("h4", null, _toDisplayString(uspCard.title), 1), _createElementVNode("p", null, _toDisplayString(uspCard.content), 1)])]);
  }), 128))])]);
}