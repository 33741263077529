export function offersFormMapper (state) {
  const contactInfo = {
    name: state.name.value,
    phone: state.phone.value,
    email: state.email.value
  };

  const companyInfo = {
    isCompany: state.isCompany.value,
    companyName: state.isCompany.value ? state.companyName.value : null,
    orgNumber: state.isCompany.value ? state.orgNumber.value : null
  };

  const eventInfo = {
    date: state.date.value,
    time: state.time.value,
    total: parseInt(state.total.value),

    streetName: state.streetName.value,
    postalCode: state.postalCode.value,
    city: state.city.value,
    municipality: state.municipality.value,

    message: state.message.value
  };

  const invoiceAddress = {
    streetName: state.sameInvoiceAddress.value
      ? state.streetName.value
      : state.invoiceStreetName.value,
    marking: state.sameInvoiceAddress.value
      ? null
      : state.invoiceMarking.value,
    postalCode: state.sameInvoiceAddress.value
      ? state.postalCode.value
      : state.invoicePostalCode.value,
    city: state.sameInvoiceAddress.value
      ? state.city.value
      : state.invoiceCity.value
  };

  const selectedOffer = {
    id: state.selectedOffer.id,
    name: state.selectedOffer.name,
    prices: state.selectedOffer.prices
  };

  return {
    contactInfo,
    companyInfo,
    eventInfo,
    invoiceAddress,
    selectedOffer
  };
}

export function contactFormMapper (state) {
  const contactInfo = {
    name: state.name.value,
    email: state.email.value
  };

  const contactData = {
    message: state.message.value
  };

  return { contactInfo, contactData };
}
