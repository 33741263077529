export default {
  name: 'invoicePostalCode',
  component: 'input',
  label: 'Faktura postnummer',
  value: '',
  prefixIcon: 'el-icon-s-order',
  autoComplete: 'billing postal-code',
  maxLength: '5',
  showWordLimit: true,
  validation: {
    parser: 'noSpace',
    rules: ['postalCode']
  }
};
