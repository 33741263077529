import { municipalities } from '@/lib/municipalities.json';

export default {
  name: 'municipality',
  component: 'select',
  label: 'Kommun',
  value: '',
  options: municipalities,
  filterable: true,
  prefixIcon: 'el-icon-location-outline',
  validation: {
    rules: ['required']
  }
};
