export default {
  name: 'time',
  component: 'time',
  label: 'Eventets tid',
  start: '00:00',
  end: '23:45',
  step: '00:15',
  value: '',
  validation: {
    rules: ['required']
  }
};
