import loginEmail from './loginEmail';
import loginPassword from './loginPassword';

import submit from './submit';

export default {
  loginEmail,
  loginPassword,

  submit
};
