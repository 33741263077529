export default {
  name: 'categories',
  component: 'select',
  label: 'Önskad mat',
  value: null,
  options: ['Allt', 'Hamburgare', 'Amerikanskt', 'Latinamerikanskt', 'Crêpes'],
  prefixIcon: 'el-icon-location-outline',
  filterable: true,
  multiple: true,
  allowCreate: false,
  validation: {
    rules: ['']
  }
};
