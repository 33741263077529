export default {
  events (state, events) {
    state.events = events;
  },
  event (state, event) {
    if (state.events.length > 0) {
      const eventIndex = state.events.findIndex(e => e.id === event.id);
      event.loaded = true;
      state.events[eventIndex] = event;
    }
  },

  archivedOffers (state, archivedOffers) {
    state.archivedOffers.value = archivedOffers;
  },
  eventsSearch (state, eventsSearch) {
    state.eventsSearch.value = eventsSearch;
  },

  loading (state, loading) {
    state.loading = loading;
  },
  error (state, error) {
    state.error = error;
  },

  readMessage (state, { id, time }) {
    // Get event with given id and message with given time and set read to true
    // after 3 seconds
    state.events.filter(e => e.id === id)[0]
      .messages
      .filter(m => new Date(m.time).getTime() === new Date(time).getTime())[0]
      .read = true;
  }
};
