export default {
  total: state => {
    return state.total;
  },
  categories: state => {
    return state.categories;
  },
  municipality: state => {
    return state.municipality;
  },
  order: state => {
    return state.order;
  },
  ascending: state => {
    return state.ascending;
  },
  preferredPartner: state => {
    return state.preferredPartner;
  },

  offers: state => {
    return state.offers;
  },
  selectedOffer: state => {
    return state.selectedOffer;
  },

  name: state => {
    return state.name;
  },
  email: state => {
    return state.email;
  },
  phone: state => {
    return state.phone;
  },

  isCompany: state => {
    return state.isCompany;
  },
  companyName: state => {
    return state.companyName;
  },
  orgNumber: state => {
    return state.orgNumber;
  },

  sameInvoiceAddress: state => {
    return state.sameInvoiceAddress;
  },
  streetName: state => {
    return state.streetName;
  },
  postalCode: state => {
    return state.postalCode;
  },
  city: state => {
    return state.city;
  },

  invoiceStreetName: state => {
    return state.invoiceStreetName;
  },
  invoiceMarking: state => {
    return state.invoiceMarking;
  },
  invoicePostalCode: state => {
    return state.invoicePostalCode;
  },
  invoiceCity: state => {
    return state.invoiceCity;
  },

  date: state => {
    return state.date;
  },
  time: state => {
    return state.time;
  },
  message: state => {
    return state.message;
  },
  compare: state => {
    return state.compare;
  },
  submit: state => {
    return state.submit;
  },

  loading: state => {
    return state.loading;
  },
  invalidSearch: state => {
    return state.invalidSearch;
  },
  error: state => {
    return state.error;
  },
  submited: state => {
    return state.submited;
  }
};
