export default {
  name: 'LoadingSpinner',
  props: {
    color: {
      type: String,
      required: false,
      default: 'green',
      validator: value => {
        return ['green', 'white'].includes(value);
      }
    }
  }
};