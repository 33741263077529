import { useField } from 'vee-validate';
import rules from '../lib/rules';
export default {
  name: 'FormInput',
  props: {
    group: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    display: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['onClick', 'update:modelValue'],

  data() {
    return {
      value: null,
      errorMessage: null
    };
  },

  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

  },

  created() {
    if (!this.content.validation) {
      return;
    }

    const fieldRules = this.content.validation && Array.isArray(this.content.validation.rules) && this.content.validation.rules.length > 0 ? Object.values(this.content.validation.rules) : undefined;
    const {
      value,
      errorMessage
    } = useField(this.content.name, rules[fieldRules[0]]);

    if (this.content.value || typeof this.content.value === 'boolean') {
      value.value = this.content.value;
    }

    if (!Array.isArray(value) && value.value || Array.isArray(value) && value.value.length > 0) {
      this.$store.commit(this.group + '/' + this.content.name, value.value);
    }

    this.value = value;
    this.errorMessage = errorMessage;
  },

  methods: {
    updateValue(value) {
      if (this.content.validation && this.content.validation.parser === 'noSpace') {
        this.value = value.replace(/\s/g, '');
      } else {
        this.value = value;
      }

      if (this.group && this.content.name) {
        this.$store.commit(`${this.group}/${this.content.name}`, value);
      } else {
        this.$emit('update:modelValue', value);
      }
    }

  }
};