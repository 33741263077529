import curbside from './curbside';
import jeffreys from './jeffreys';
import hkslila from './hkslila';
import soulistic from './soulistic';
import laolita from './laolita';
import whatsoup from './whatsoup';
import asianmix08 from './asianmix08';
import tanntayaasayes from './tanntayaasayes';
import gurras from './gurras.json';
import andina from './andina';
import baobowl from './baobowl';
import haileys from './haileys';
import bestburger from './bestburger';
import smakligmaltid from './smakligmaltid';
import yarmouxfalafel from './yarmouxfalafel';
import jerusalemkoket from './jerusalemkoket';
import premiumburger from './premiumburger';
import latinoStreetFood from './latinoStreetFood';
import rullandeGreken from './rullandegreken';
import pinktruck from './pinktruck';
import churroskungarna from './churroskungarna';
import whatacrepe from './whatacrepe';

export default [
  curbside,
  laolita,
  soulistic,
  hkslila,
  jeffreys,
  baobowl,
  asianmix08,
  haileys,
  andina,
  gurras,
  bestburger,
  smakligmaltid,
  yarmouxfalafel,
  jerusalemkoket,
  premiumburger,
  latinoStreetFood,
  rullandeGreken,
  pinktruck,
  whatsoup,
  tanntayaasayes,
  churroskungarna,
  whatacrepe
];
