import fields from '@/forms/login';

const user = JSON.parse(localStorage.getItem('user'));

export default {
  loggedIn: !!user,
  address: null,
  id: user ? user.id : null,
  createdAt: user ? user.createdAt : null,
  name: null,
  email: null,
  loginEmail: fields.loginEmail,
  loginPassword: fields.loginPassword,
  repeatedPassword: null,
  recoveryToken: null,
  phone: null,
  role: user ? user.role : null,
  isCompany: null,
  companyName: null,
  orgNumber: null,
  loading: false,
  error: false
};
