export default {
  name: 'message',
  component: 'input',
  type: 'textarea',
  label: 'Övriga önskemål',
  value: '',
  prefixIcon: 'el-icon-chat-line-square',
  autosize: { minRows: 3 },
  validation: {
    rules: ['']
  }
};
