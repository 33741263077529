export default {
  name: 'phone',
  component: 'input',
  label: 'Telefon nummer',
  value: '',
  prefixIcon: 'el-icon-phone-outline',
  validation: {
    rules: ['phone']
  }
};
