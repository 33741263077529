import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag-next';

import filters from '@/lib/filters';

const app = createApp(App).use(store).use(router).use(VueGtag, {
  isEnabled: false,
  property: [{
    id: 'G-K1B356W54G',
    params: {
      traffic_type: process.env.NODE_ENV !== 'production' ? 'internal' : '',
      send_page_view: true,
      linker: {
        domain: ['caterify.se']
      }
    }
  }, {
    id: 'AW-988848528',
    params: {
      traffic_type: process.env.NODE_ENV !== 'production' ? 'internal' : '',
      send_page_view: true
    }
  }]
});

app.config.globalProperties.$filters = {
  ...filters
};

app.mount('#app');
