export default {
  loggedIn: state => state.loggedIn,
  address: state => state.address,
  id: state => state.id,
  createdAt: state => state.createdAt,
  name: state => state.name,
  email: state => state.email,
  loginEmail: state => state.loginEmail,
  loginPassword: state => state.loginPassword,
  repeatedPassword: state => state.repeatedPassword,
  recoveryToken: state => state.recoveryToken,
  phone: state => state.phone,
  role: state => state.role,
  isCompany: state => state.isCompany,
  companyName: state => state.companyName,
  orgNumber: state => state.orgNumber,
  loading: state => state.loading,
  error: state => state.error
};
