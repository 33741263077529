import * as Cookie from 'tiny-cookie';
import { useState, useGtag } from 'vue-gtag-next';
const STORAGE_TYPES = {
  local: 'localStorage',
  cookies: 'cookies'
};
export default {
  name: 'CookieConsent',

  setup() {
    const {
      isEnabled
    } = useState();
    const {
      event
    } = useGtag();

    const enable = () => {
      isEnabled.value = true;
      event('conversion', {
        send_to: 'AW-988848528/bWlDCKPCpNcBEJDDwtcD'
      });
    };

    return {
      enable
    };
  },

  data() {
    return {
      supportsLocalStorage: true,
      isOpen: false,
      showMoreInfo: false,
      storageName: 'cookie:accepted',
      storageType: STORAGE_TYPES.local
    };
  },

  computed: {
    canUseLocalStorage() {
      return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage;
    }

  },

  created() {
    if (this.storageType === STORAGE_TYPES.local) {
      // Check for availability of localStorage
      try {
        const test = '__vue-cookielaw-check-localStorage';

        if (typeof window !== 'undefined') {
          window.localStorage.setItem(test, test);
          window.localStorage.removeItem(test);
        }
      } catch (e) {
        console.info('Local storage is not supported, falling back to cookie use');
        this.supportsLocalStorage = false;
      }
    }

    if (!this.getVisited()) {
      this.isOpen = true;
    }
  },

  mounted() {
    if (process.env.NODE_ENV === 'production' && this.isAccepted()) {
      this.enable();
      this.setupHotjar();
    }
  },

  methods: {
    setVisited() {
      if (this.canUseLocalStorage) {
        localStorage.setItem(this.storageName, true);
      } else {
        Cookie.set(this.storageName, true);
      }
    },

    setAccepted() {
      if (this.canUseLocalStorage) {
        localStorage.setItem(this.storageName, true);
      } else {
        Cookie.set(this.storageName, true);
      }
    },

    setDeclined() {
      if (this.canUseLocalStorage) {
        localStorage.setItem(this.storageName, false);
      } else {
        Cookie.set(this.storageName, false);
      }
    },

    getVisited() {
      let visited = false;

      if (this.canUseLocalStorage) {
        visited = localStorage.getItem(this.storageName);
      } else {
        visited = Cookie.get(this.storageName);
      }

      if (typeof visited === 'string') {
        visited = JSON.parse(visited);
      }

      return !(visited === null || visited === undefined);
    },

    isAccepted() {
      let accepted = false;

      if (this.canUseLocalStorage) {
        accepted = localStorage.getItem(this.storageName);
      } else {
        accepted = Cookie.get(this.storageName);
      }

      if (typeof accepted === 'string') {
        accepted = JSON.parse(accepted);
      }

      return accepted;
    },

    accept() {
      this.setVisited();
      this.setAccepted();
      this.isOpen = false;
      this.enable();
      this.setupHotjar();
    },

    setupHotjar() {
      //  Hotjar Tracking Code for https://caterify.se
      var script = document.createElement('script');
      script.innerHTML = `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2739774,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(script);
    },

    decline() {
      this.setVisited();
      this.setDeclined();
      this.isOpen = false;
    },

    open() {
      if (!this.getVisited()) {
        this.isOpen = true;
      }
    }

  }
};