import { mapGetters, useStore } from 'vuex';
import { useForm } from 'vee-validate';
import FormInput from '@/components/FormInput';
import Loading from '@/components/Loading';
export default {
  name: 'ContactUs',
  components: {
    FormInput,
    Loading
  },

  setup() {
    const store = useStore();
    const {
      handleSubmit,
      isSubmitting
    } = useForm();
    const onSubmit = handleSubmit(() => {
      store.dispatch('contactUs/submitContactForm');
    });
    return {
      onSubmit,
      isSubmitting
    };
  },

  computed: { ...mapGetters('contactUs', ['name', 'email', 'message', 'submit', 'loading', 'submited', 'error'])
  }
};