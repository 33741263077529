import { ElCard } from 'element-plus';
import partners from '@/content/Partners';
export default {
  name: 'PartnerCards',
  components: {
    ElCard
  },
  props: {
    show: {
      type: Number,
      required: false,
      default: null
    }
  },

  setup(props) {
    var processedPartners = partners;

    if (props.show && parseInt(props.show)) {
      processedPartners = processedPartners.filter((_, index) => index < props.show);
    }

    processedPartners = processedPartners.map(partner => {
      return {
        slug: '/' + partner.id,
        name: partner.name,
        description: partner.description.slice(0, 99) + '...',
        imageUrl: partner.image
      };
    });
    return {
      partners: processedPartners
    };
  },

  methods: {
    imageUrl(imageUrl) {
      return require('../../assets/partners/' + imageUrl);
    }

  }
};