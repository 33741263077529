export default {
  name: 'companyName',
  component: 'input',
  label: 'Företagsnamn',
  value: '',
  prefixIcon: 'el-icon-s-cooperation',
  validation: {
    rules: ['required']
  }
};
