import Home from '@/content/Home';
import Jumbo from '@/components/Jumbo';
import SearchOffers from '@/fragments/SearchOffers';
import Usps from '@/components/Usps';
import UspCards from '@/components/UspCards';
import PartnerCards from '@/components/Partners/PartnerCards.vue';
import Accordion from '@/components/Accordion';
import ArticleCards from '@/components/Articles/ArticleCards';
export default {
  name: 'Home',
  components: {
    Jumbo,
    SearchOffers,
    Usps,
    UspCards,
    PartnerCards,
    Accordion,
    ArticleCards
  },

  data() {
    return {
      Home
    };
  }

};