import axios from 'axios';
import store from '../store';
import router from '@/router';

const { SERVICE_USER, SERVICE_CATERIFY } = require('config');

const serviceUser = axios.create({
  baseURL: SERVICE_USER,
  withCredentials: true
});
const serviceCaterify = axios.create({
  baseURL: SERVICE_CATERIFY,
  withCredentials: true
});

serviceUser.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      store.dispatch('user/loggedOut').then(function () {
        router.push({ path: '/mina-sidor/login' });
      });
    }
    return Promise.reject(error);
  }
);

serviceCaterify.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      store.dispatch('user/loggedOut').then(function () {
        router.push({ path: '/mina-sidor/login' });
      });
    }
    return Promise.reject(error);
  }
);

// AXIOS global configs
axios.defaults.withCredentials = true;
axios.serviceUser = serviceUser;
axios.serviceCaterify = serviceCaterify;

export default {
  serviceUser,
  serviceCaterify
};
