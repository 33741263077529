export default {
  name: 'invoiceCity',
  component: 'input',
  label: 'Faktura postort',
  value: '',
  prefixIcon: 'el-icon-s-order',
  autoComplete: 'billing region',
  validation: {
    rules: ['required']
  }
};
