import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: state,
  mutations,
  getters,
  actions
};
