import router from '@/router';
import store from '../../';

const { serviceUser } = require('@/lib/axios').default;
const { useGtag } = require('vue-gtag-next');

function GATrack (action, category, label) {
  const { event } = useGtag();

  event(action, {
    event_category: category,
    event_label: label
  });
}

export default {
  login ({ state, commit }) {
    commit('loading', true);
    serviceUser.post('/user/login', {
      email: state.loginEmail.value,
      password: state.loginPassword.value
    })
      .then(({ data }) => {
        commit('user', data.user);
        commit('loggedIn', true);
        commit('error', false);

        GATrack('login successfully', 'user', 'login');
        // Go to /mina-sidor
        router.push({ path: '/mina-sidor' });
      }).catch(({ response }) => {
        if (response && response.status === 401) {
          commit('user', null);
        }
        commit('error', response && response.data && response.data.code ? response.data.code : '500');
        commit('loginPassword', null);
        commit('loggedIn', false);

        GATrack('login not successfully', 'user', 'login');
      }).finally(() => {
        commit('loading', false);
      });
  },

  logout ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('loading', true);
      serviceUser.post('/user/logout')
        .then(() => {
          commit('user', null);
          commit('loggedIn', false);

          GATrack('logout successfully', 'user', 'logout');
          commit('loading', false);
          router.push({ path: '/' });
          dispatch('loggedOut');
          resolve();
        }).catch(e => {
          commit('user', null);
          commit('loggedIn', false);

          GATrack('logout not successfully', 'user', 'logout');
          commit('loading', false);
          reject(e);
        });
    });
  },

  loggedOut ({ commit }) {
    return new Promise((resolve) => {
      commit('loading', true);

      commit('user', null);
      commit('loggedIn', false);

      store.dispatch('events/clearMyEvents');
      commit('loading', false);
      resolve();
    });
  },

  getUser ({ commit }) {
    commit('loading', true);
    serviceUser.get('/user')
      .then(({ data }) => {
        commit('user', data.user);
        commit('loggedIn', true);
        commit('error', false);
      }).catch(e => {
        if (e.response && e.response.status === 401) {
          commit('user', null);
        }
        commit('error', true);
        commit('loggedIn', false);
      }).finally(() => {
        commit('loading', false);
      });
  },

  recoverPassword ({ state, commit }) {
    commit('loading', true);
    serviceUser.post('/recover', {
      email: state.loginEmail.value
    })
      .then(_ => {
        commit('error', false);

        GATrack('Recover password successfully', 'user', 'recoverPassword');
      }).catch(e => {
        commit('error', true);
        commit('loggedIn', false);

        GATrack('Recover password not successfully', 'user', 'recoverPassword');
      }).finally(() => {
        commit('loading', false);
      });
  },

  resetPassword ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('loading', true);
      serviceUser.post(`/recover/${state.recoveryToken}`, {
        email: state.loginEmail.value,
        password: state.loginPassword.value,
        repeatedPassword: state.repeatedPassword
      })
        .then(() => {
          GATrack('reset password successfully', 'user', 'resetPassword');

          commit('error', false);
          resolve();
        }).catch(_ => {
          GATrack('reset password successfully', 'user', 'resetPassword');

          commit('error', true);
          commit('loggedIn', false);
          reject(new Error('error'));
        }).finally(() => {
          commit('loading', false);
        });
    });
  }
};
