export default {
  name: 'streetName',
  component: 'input',
  label: 'Adress',
  value: '',
  prefixIcon: 'el-icon-location-information',
  maxLength: 32,
  validation: {
    rules: ['required']
  }
};
