export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    accordions: {
      type: Array,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      expandedSection: -1,
      expandedQuestion: -1
    };
  },

  methods: {
    toggleExpanded(questionIndex) {
      this.expandedQuestion = this.expandedQuestion === questionIndex ? -1 : questionIndex;
    }

  }
};