import TypeWriter from './TypeWriter';
export default {
  components: {
    TypeWriter
  },

  data() {
    return {
      words: ['festen...', 'bröllopet...', 'kalaset...', 'bygglunchen...']
    };
  }

};