export default {
  currency (value) {
    if (value) {
      return value.toLocaleString('sv-SE') + '\xa0kr';
    }
    return value;
  },
  thousand (value) {
    if (value) {
      return value.toLocaleString('sv-SE');
    }
    return value;
  },
  phone (value) {
    if (value) {
      return value.replace(/^([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})$/, '$1-$2 $3 $4');
    }
  },
  date (value) {
    if (value) {
      const dates = value.split('-');
      const month =
        ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'][dates[1] - 1];
      return `${dates[2]} ${month} ${dates[0]}`;
    }
    return value;
  },
  datetime (value) {
    if (value) {
      return new Date(value).toLocaleString('sv-SE', {
        timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
      });
    }
    return value;
  }
};
