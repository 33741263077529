import { contactFormMapper } from '../../mapper';
import fields from '@/forms/contactUs';

const { serviceCaterify } = require('@/lib/axios').default;

const { useGtag } = require('vue-gtag-next');

function GATrack (action, category, label, value) {
  const { event } = useGtag();

  event(action, {
    event_category: category,
    event_label: label,
    event_value: value
  });
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    name: fields.name,
    email: fields.email,
    message: fields.message,
    submit: fields.submit,
    loading: false,
    error: false,
    submited: false
  },
  mutations: {
    name (state, name) {
      state.name.value = name;
    },
    email (state, email) {
      state.email.value = email;
    },
    message (state, message) {
      state.message.value = message;
    },
    loading (state, loading) {
      state.loading = loading;
    },
    error (state, error) {
      state.error = error;
    },
    submited (state, submited) {
      state.submited = submited;
    }
  },
  getters: {
    name: state => {
      return state.name;
    },
    email: state => {
      return state.email;
    },
    message: state => {
      return state.message;
    },
    submit: state => {
      return state.submit;
    },
    loading: state => {
      return state.loading;
    },
    error: state => {
      return state.error;
    },
    submited: state => {
      return state.submited;
    }
  },
  actions: {
    submitContactForm ({ state, commit }) {
      commit('loading', true);

      const { contactInfo, contactData } = contactFormMapper(state);

      serviceCaterify.post('/contact', {
        contactInfo,
        contactData
      }).then(({ data }) => {
        commit('loading', false);
        if (data.success) {
          commit('submited', true);
          commit('error', false);

          GATrack('contact successfully', 'contact', 'contact');
        } else {
          commit('error', true);

          GATrack('contact not successfully', 'contact', 'contact');
        }
      }).catch((e) => {
        commit('error', true);
        commit('loading', false);

        GATrack('contact not successfully', 'contact', 'contact');
      });
    }
  }
};
